import { useState } from 'react';
import { compose } from 'redux';

import { isReady } from 'store/status';
import { Loader } from 'modules/v2/common/components';

import { Box } from 'modules/v2/common/AtomicDesign/atoms';
import { ProfileTemplate } from 'modules/v2/templates';
import { withProfile } from 'modules/v2/containers';
import { withAuth } from 'modules/auth/containers';
import { PersonalInformationForm } from 'modules/v2/common/AtomicDesign/organisms';

const Profile = ({ avatar, profile, signOut }) => {
  const [loadingText, setLoadingText] = useState('Loading Profile');

  const {
    data: { email, firstName, lastName, phone },
  } = profile;

  const fields = {
    email,
    firstName,
    lastName,
    phone,
  };

  let content = <Loader title={loadingText} height="100%" />;

  if (isReady(profile.status)) {
    content = (
      <PersonalInformationForm />
    );
  }

  return (
    <ProfileTemplate
      avatar={avatar}
      email={fields.email}
      title="Account Settings"
      username={fields.firstName}
      onLogout={signOut}
      sidebarWidth={0}
      noPaddingLeft
      noPaddingRight
    >
      <Box>
        <div className="text-base font-semibold px-6 py-[22px]  text-boxTitle border-b border-neutral-200">
          Personal information
        </div>

        <div className="p-6">
          {content}
        </div>
      </Box>
    </ProfileTemplate>
  );
};

export default compose(withProfile, withAuth)(Profile);