import { DashboardTemplate } from 'modules/v2/templates';
import { Box, Button } from 'modules/v2/common/AtomicDesign/atoms';
import { InfoIconNew } from 'modules/v2/common/components/SvgIcon';
import { isSucceeded, isLoading } from 'store/status';
import { Iframe } from 'modules/v2/draft/components';

const PreviewGuide = ({ setIsPublishedGuide }) => {
  const mockData = {
    title: 'A Guide To Selling Your Home',
    PDF: 'https://afy-staging-book-covers.s3.amazonaws.com/pdf-flipbook/index.html?file=aHR0cHM6Ly9hdXRob3JpZnktZ2VuZXJhdGVkLXBkZi5zMy5hbWF6b25hd3MuY29tLzY3NDA2NzgxNTQzL3ByZXZpZXdzLzY3NDA2NzgxNTQzLTY3MGZkM2E5Y2ZhMWFiMDAyYWIwMGVmZC5wZGY%2FdGltZXN0YW1wPTE3MjkwOTA0ODc4OTQ%3D',
    status: 'STATUS_SUCCESS',
    lastEdited: 'Oct 21, 2024 9:24 PM',
    isDigital: false,
  };

  const isGuideReady = isSucceeded(mockData.status);

  const getStatus = (status) => {

    if (isLoading(status)) {
      return (
        <span className="text-primary-500 bg-primary-50 px-2 py-1 rounded-md text-sm font-medium">
          Publishing...
        </span>
      );
    }

    if (isGuideReady) {
      return (
        <span className="text-success-500 bg-success-50 px-2 py-1 rounded-md text-sm font-medium">
          Published
        </span>
      );
    }

    return null;
  };

  return (
    <DashboardTemplate>
      <div className='flex items-center justify-between flex-col md:flex-row flex-col gap-3'>
        <h2 className="text-2xl font-extrabold mb-0">{mockData.title}</h2>
        <div className='flex gap-3.5'>
          <Button color="outlined" >Download PDF</Button>
          {!mockData.isDigital && <div className='w-[114px]'>
            <Button color="primary" full >Order</Button>
          </div>}
        </div>
      </div>
      <div className='flex w-full bg-neutral-100 my-6 py-2.5 px-3 rounded-md gap-3 font-semibold text-sm'>
        <InfoIconNew />
        To order, click on "Order" to continue with the order flow.
      </div>

      <div className="flex gap-6 max-[1280px]:flex-col">
        <Box className="p-6 w-full min-w-[340px] min-h-[545px]">
          <div className="mb-4 flex justify-between">
            <span className="h-[46px] flex items-center text-base">Your Guide + Infographic</span>
            <div className='w-[110px]'>
              <Button color="outlined" size="base" full onClick={() => setIsPublishedGuide(false)}>
                Edit
              </Button>
            </div>

          </div>
          <div className="h-[734px] overflow-hidden">
            <Iframe
              isLoading={false}
              hasError={false}
              height="734px"
              src={mockData.PDF}
            />
          </div>
        </Box>

        <div className="flex-col w-[389px] max-[1280px]:w-full">
          <Box className="mb-6 min-w-[340px] max-w-[390px] max-[1280px]:max-w-full">
            <div className="flex justify-between border-b-[1px] border-neutral-200 items-center mx-6 py-[14px] mb-[14px]">
              <div className="text-boxTitle">Status</div>
              {getStatus(mockData.status)}
            </div>
            <div className="flex justify-between items-center mx-6 py-[14px] mb-[14px]">
              <div className="text-boxTitle">Last edited</div>
              <span className="text-neutral-800 text-sm font-medium">
                {mockData.lastEdited}
              </span>
            </div>
          </Box>

        </div>
      </div>
    </DashboardTemplate>
  );
};

export default PreviewGuide;