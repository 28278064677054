import { call, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/v2/common/utils';
import { updateGlobalProfileMockData, resource, getError } from 'modules/api';
import { UPDATE_GLOBAL_PROFILE } from 'modules/dashboard/store/constants';

function* onUpdateGlobalProfile() {
  try {
    const request = resource(UPDATE_GLOBAL_PROFILE, updateGlobalProfileMockData);
    const response = yield call(request);

    return response;
  } catch (error) {
    const response = getError(error);
    // eslint-disable-next-line no-console
    console.error('Error updating the global profile:', response);

    notification.error({ description: 'Something went wrong. Please try again' });
    return response;
  }
}

export function* watchUpdateGlobalProfile() {
  yield takeLatest(UPDATE_GLOBAL_PROFILE, onUpdateGlobalProfile);
}