import { BoltIcon, TickMark } from 'modules/v2/common/components/SvgIcon';
import { useHistory } from 'react-router-dom';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { cardImageUrl } from 'modules/v2/utils/hideBookAccess';
import { getRoutePlanUpgrade } from 'modules/v2/routes/navigation';

function ModalSubscribeBook({ setOpenModal }) {
  const history = useHistory();

  const handleSubscribeClick = () => {
    history.push(getRoutePlanUpgrade());
  };

  const handleClick = () => {
    setOpenModal(false);
  };

  return (
    <div className='flex items-start self-stretch bg-white rounded-lg gap-1 w-full'>
      <div className='p-5 w-[50%]'>
        <div className='flex text-sm font-semibold py-2'>
          <span className='pr-2'><BoltIcon fill='#FFC229' /></span>
          Buy now to use this feature
        </div>
        <div className='text-2xl font-bold py-2'>
          Your Very Own Printed Book
        </div>
        <div className='text-base font-medium py-2'>
          Publishing your book is a powerful way to set yourself apart. Your Authorify Book Membership includes:
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Customize your Book.</span> Get your professionally printed books with your own headshot and bio to impress your leads.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Lead Capture Site.</span> Get more leads. This will help you convert cold traffic into warm leads.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Digital Book.</span> Easiest way to do a presentation with an out-of-town client. You can quickly and easily share your book with anyone in the world.          
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Campaigns.</span> Send your books directly to leads, while also engaging them through targeted email campaigns.
          </div>
        </div>
        <div className='gap-2 mt-4 mb-2 w-full'>
          <Button type='primary' full wrapperClassName="w-full" onClick={handleSubscribeClick}><span className='pr-1'><BoltIcon /></span> Buy Books</Button>
        </div>
        <div className='gap-2 mt-4 mb-2 w-full'>
          <Button type='outlined' full wrapperClassName="w-full" onClick={handleClick} >Do it later</Button>
        </div>
      </div>
      <div className='justify-center items-center gap-2.5 self-stretch w-[50%]'>
        <img className='rounded-r-lg h-full w-full' src={cardImageUrl} alt='book-asset'/>
      </div>
    </div>
  );
}

export default ModalSubscribeBook;