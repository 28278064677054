import styled from 'styled-components';
import { Modal } from 'modules/v2/common/components';

const ModalWrapper = styled(Modal)`
  .ant-modal-content {
    background-color: transparent;
    width: 700px;
  }
`;

export default {
  ModalWrapper,
};
