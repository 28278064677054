import { useState } from 'react';
import { useQuery } from 'react-query';
import { isEmpty } from 'lodash';
import { DashboardTemplate } from 'modules/v2/templates';
import { getGuideContent } from 'modules/api/strapi';

import { GuidePreview } from './components/GuidePreview';
import mockData, { personalInformation } from './mocks/index';

const GuideCatalog = () => {
  const [loadingMessage, setLoadingMessage] = useState('Loading Guide Catalog...');
  const { data: guideResponse, isLoading:isGuideLoading } = useQuery('getGuideContent', getGuideContent);

  let frontCoverResponse;
  if(!isEmpty(guideResponse)){
    frontCoverResponse = guideResponse.data[0]?.attributes.frontCover;
  }

  return (
    <DashboardTemplate title="Guide Catalog" isLoading={isGuideLoading} loadingTitle={loadingMessage} contentTitle="Guide Catalog">
      <div className="bg-neutral-0 py-[15px] px-6 rounded-b-lg shadow-box">
        <div className="grid gap-5 grid-cols-[repeat(auto-fill,252px)] w-full justify-center py-[30px] px-6">
          {Array.from({ length: 1 }).map(() => (
            <GuidePreview guideDetails={mockData[0]} frontCoverResponse={frontCoverResponse} personalInformation={personalInformation} />
          ))}
        </div>
      </div>
    </DashboardTemplate>
  );
};

export default GuideCatalog;
