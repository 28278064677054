export const customLoadingModalTheme = {
    root: {
      show: {
        on: 'bg-gray-700 bg-opacity-50 fixed inset-0 z-40 flex items-center justify-center',
      },
    },
    content: {
      base: 'relative pt-4 w-fit w-[440px] md:h-auto mx-auto bg-white rounded-lg shadow-lg md:h-auto',
    },
    footer: {
      base: 'flex space-x-2 rounded-b border-neutral-100 min-h-[91px] px-6 py-[13px]',
    },
  };