import { useState } from 'react';
import { includes } from 'lodash-es';

import { AvatarCropper, ImageUploader, LogoCropper } from './components';
import ImageCropper from './components/ImageCropper';

const FilePicker = ({
  type,
  onSelect,
  handleImageChange,
  noMargin,
  onSuccess,
  ...imageProps
}) => {
  const [selected, setSelected] = useState(null);
  const [imageFormat, setImageFormat] = useState();

  const onSelectImage = async (file) => {
    if (file) {
      const url = URL.createObjectURL(file);
      setImageFormat(file.type);
      setSelected(url);
      if (handleImageChange) {
        handleImageChange(file, url);
      }
    }
  };

  const handleCancel = () => {
    setSelected(null);
  };
  const handleSelect = (image) => {
    if (typeof onSelect === 'function') onSelect(image, imageFormat);
  };

  if (selected === null || type === 'preview-book-image' || type === 'email-template-header') {
    return (
      <ImageUploader
        noMargin={noMargin}
        onSelect={onSelectImage}
        imageType={type}
        {...imageProps}
      />
    );
  }

  if (type === 'profile') {
    return <AvatarCropper image={selected} onChange={handleSelect}  handleCancel={handleCancel} onSuccess={onSuccess} />;
  }
  if (
    type === 'rm_profile' ||
    type === 'book_profile' ||
    type === 'brokerageLogo' ||
    type === 'companyLogo' ||
    type === 'backInsideCoverImageOption3' ||
    type === 'backInsideCoverImageOption4' ||
    type === 'backInsideCoverImageOption6' ||
    type === 'backCoverImageOption4' ||
    type === 'backCoverImageOption5' ||
    type === 'backCoverImageOption3'
  ) {
    return (
      <ImageCropper
        handleCancel={handleCancel}
        type={type}
        image={selected}
        onChange={handleSelect}
        onSuccess={onSuccess}
      />
    );
  }

  if (includes(['logo', 'content', 'background'], type)) {
    return <LogoCropper image={selected} onChange={handleSelect} handleCancel={handleCancel} />;
  }

  return null;
};

export default FilePicker;
