export const GuideCheckoutStatus = {
    NEW: 'new',
    ORDERED: 'ordered',
    FINALIZE: 'finalize'
  };
  
  export const GuideCheckoutPlaceOrderButton = {
    SHIPNOW: 'Ship Now',
    PLACEORDER: 'Place Order',
  };
  
  export const GuideStatusButton = {
    NEW: 'Buy Now',
    ORDERED: 'Buy Again',
    FINALIZED: 'Finalize Shipment'
  };
  
  export const DigitalOption = {
    name: 'Digital Only',
  };
  
  export const productsOptions = [
    {
      id: 1,
      label: '25 Printed Copies',
      qty: 25,
      price: '115',
    },
    {
      id: 2,
      label: '50 Printed Copies',
      price: '210',
      qty: 50,
    },
    {
      id: 3,
      label: '100 Printed Copies',
      price: '390',
      qty: 100,
    },
    {
      id: 4,
      label: '150 Printed Copies',
      price: '545',
      qty: 150,
    },
    {
      id: 5,
      label: '200 Printed Copies',
      price: '675',
      qty: 200,
    },
    {
      id: 6,
      label: '250 Printed Copies',
      price: '785',
      qty: 250,
    },
  ];
  
  export const DigitalPrintOptions = {
    id: 99,
    label: 'Digital Only',
    qty: 1,
    price: '99',
  };  