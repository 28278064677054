export const OrderHistoryIcon = ({ width = 18, height = 19, fill = '#252525' }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 18 19"
      fill={fill}
    >
      <path
        d="M14.6251 7.37498L14.0923 5.42097C13.8869 4.66752 13.7841 4.29079 13.5734 4.00708C13.3636 3.72453 13.0785 3.50674 12.7507 3.37862C12.4215 3.24998 12.031 3.24998 11.2501 3.24998M3.37509 7.37498L3.90789 5.42097C4.11333 4.66752 4.21605 4.29079 4.42675 4.00708C4.63658 3.72453 4.92171 3.50674 5.24951 3.37862C5.57865 3.24998 5.96913 3.24998 6.75009 3.24998M11.2501 3.24998C11.2501 3.6642 10.9143 4 10.5001 4H7.50009C7.08588 4 6.75009 3.6642 6.75009 3.24998M11.2501 3.24998C11.2501 2.83577 10.9143 2.5 10.5001 2.5H7.50009C7.08588 2.5 6.75009 2.83577 6.75009 3.24998M7.26358 16H10.7366C12.424 16 13.2677 16 13.878 15.5235C14.4884 15.0469 14.693 14.2284 15.1022 12.5914C15.7457 10.0176 16.0674 8.73065 15.3918 7.86532C14.7162 7 13.3897 7 10.7366 7H7.26358C4.61053 7 3.284 7 2.60837 7.86532C1.93275 8.73065 2.25448 10.0176 2.89794 12.5914C3.30719 14.2284 3.51182 15.0469 4.12216 15.5235C4.7325 16 5.57619 16 7.26358 16Z"
        stroke="#252525"
        strokeWidth="1.5"
      />
    </svg>
  );