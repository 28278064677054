import env from 'environments';
import { BoltIcon, TickMark } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { cardMagazineImageUrl } from 'modules/v2/utils/hasOnlyRMAccess';



function SubscribeMagazine() {
  const handleLearnMoreClick = () => {
    window.open(env.RM_SITE_URL, '_blank');
  };

  const handleSubscribeClick = () => {
    window.open(env.RM_SITE_URL, '_blank');
  };

  return (
    <div className='flex items-start self-stretch p-6 bg-white rounded-lg shadow-md gap-5 w-full mb-6'>
      <div className='justify-center items-center gap-2.5 self-stretch w-[40%]'>
        <img className='rounded-lg h-full w-full' src={cardMagazineImageUrl} alt='magazine-asset'/>
      </div>
      <div className='w-[60%]'>
        <div className='text-lg font-bold'>
        Why Referral Marketing?
        </div>
        <div>
          Did you know referral marketing is nine times more powerful than cold calling or open house marketing? It's true.
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>91% of buyers would use their agent again or recommend their agent to others</span>  -NAR profile report
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>9x more sellers found their agent through Repeat/Referral Business than did through Cold Calling or an Open House.</span>
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>73% of buyers interviewed only one real estate agent during their home search</span> -NAR profile report
          </div>
        </div>
        <div className='flex gap-2 mt-4 mb-2'>
          <Button type='primary' onClick={handleSubscribeClick}><span className='pr-1'><BoltIcon /></span> Buy Magazines </Button>
          <Button type='muted' onClick={handleLearnMoreClick}>Learn more</Button>
        </div>
      </div>
    </div>
  );
}

export default SubscribeMagazine;