import { call, takeLatest } from 'redux-saga/effects';
import { notification } from 'modules/v2/common/utils';
import { getGlobalProfileMockData, resource, getError } from 'modules/api';
import { GET_GLOBAL_PROFILE } from 'modules/dashboard/store/constants';

function* onGetGlobalProfile() {
  try {
    const request = resource(GET_GLOBAL_PROFILE, getGlobalProfileMockData);
    const response = yield call(request);

    return response;
  } catch (error) {
    const response = getError(error);
    // eslint-disable-next-line no-console
    console.error('Error getting the global profile:', response);

    notification.error({ description: 'There was something wrong with Setup Checklist' });
    return response;
  }
}

export function* watchGetGlobalProfile() {
  yield takeLatest(GET_GLOBAL_PROFILE, onGetGlobalProfile);
}