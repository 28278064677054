import { useState } from 'react';
import EditGuide from './components/EditGuide';
import PreviewGuide from './components/PreviewGuide';

const GuidePreview = ({ history }) => {
  const [ isPublishedGuide, setIsPublishedGuide ] = useState<boolean>(false);


  return (
    isPublishedGuide ? <PreviewGuide setIsPublishedGuide={setIsPublishedGuide} /> : <EditGuide history={history} setIsPublishedGuide={setIsPublishedGuide} />
  );
};

export default GuidePreview;