import env from 'environments';
import { hasSocialMediaTrainingAccess } from 'modules/v2/utils';
import {
  GridIcon,
  NewBookIcon,
  MagazineIcon,
  CampaignIcon,
  LeadIcon,
  TrainingIcon,
  RouteSignIcon,
  GuidesIcon,
} from 'modules/v2/common/components/SvgIcon';
import { LightningBoltIcon } from 'modules/v2/common/components/SvgIcon';
import { neutral300 } from 'modules/v2/common/theme/color';

interface children {
  key: string;
  name: string;
  href: string;
}

interface navigationInteface {
  key: string; // key needs to be present on the route path to highlight the current route
  name: string;
  href?: string;
  icon: any;
  actionIcon?: any;
  count?: string;
  current?: boolean;
  open?: boolean;
  children?: children[];
}

const TrainingHubUrl = `${env.SOCIAL_TRAINING_URL}`;
const SocialMediaTrainingUrl = `${env.SOCIAL_MEDIA_TRAINING_URL}`;
const MortgageTrainingUrl = `${env.SOCIAL_TRAINING_URL}/mortgage/`;
const showMagazineRecipients = env.VITE_MAGAZINE_RECIPIENTS_FEATURE;

const navigationRoutes: navigationInteface[] = [
  {
    key: 'welcome-journey',
    name: 'Welcome journey',
    href: '/welcome-journey',
    icon: RouteSignIcon,
  },
  {
    key: 'dashboard',
    name: 'Dashboard',
    href: '/',
    icon: GridIcon,
  },
  {
    key: 'my-guides',
    name: 'Guides',
    icon: GuidesIcon,
    href: '/my-guides/guide-catalog',
  },
  {
    key: 'my-books-guides',
    name: 'Books',
    icon: NewBookIcon,
    actionIcon: <LightningBoltIcon fill={neutral300}/>,
    href: '/my-books/book-catalog',
  },
  {
    key: 'referral-marketing-guides',
    name: 'Magazines',
    icon: MagazineIcon,
    actionIcon: <LightningBoltIcon fill={neutral300}/>,
    href: '/magazines/guides',
  },
  {
    key: 'my-books',
    name: 'Books',
    icon: NewBookIcon,
    children: [
      { key: 'Book-Catalog', name: 'Book catalog', href: '/my-books/book-catalog' },
      {
        key: 'Ordered-books',
        name: 'Ordered books',
        href: '/my-books/my-orders',
      },
      {
        key: 'Digital-books',
        name: 'Digital books',
        href: '/my-books/digital-books',
      },
      {
        key: 'Book-analytics',
        name: 'Book analytics',
        href: '/my-books/book-performance',
      },
    ],
  },
  {
    key: 'referral-marketing',
    name: 'Magazines',
    icon: MagazineIcon,
    children: [
      {
        key: 'RM-Magazine-editor',
        name: 'RM Magazine editor',
        href: `/referral-marketing/magazine-editor/${env.VITE_APP_RMM_CURRENT_MONTH}/2024`,
      },
      {
        key: 'Generated-magazine',
        name: 'Active magazine',
        href: '/referral-marketing/generated-magazine',
      },
      {
        key: 'Previous-magazine',
        name: 'Previous magazine',
        href: '/referral-marketing/previous-magazines',
      },
      showMagazineRecipients && {
        key: 'Magazine-recipients',
        name: 'Magazine recipients',
        href: '/referral-marketing/magazine-recipients',
      },
    ].filter(Boolean),
  },
  {
    key: 'email-campaign',
    name: 'Campaigns',
    icon: CampaignIcon,
    children: [
      {
        key: 'Sender-email',
        name: 'Sender email',
        href: '/dashboard/email-campaign/sender-email-configuration',
      },
      {
        key: 'Create-email-campaign',
        name: 'Create email campaign',
        href: '/dashboard/email-campaign/campaigns/launch-campaign',
      },
      {
        key: 'Template-emails',
        name: 'Template emails',
        href: '/dashboard/email-campaign/templates',
      },
      {
        key: 'Campaign-metrics',
        name: 'Campaign analytics',
        href: '/dashboard/email-campaign/campaigns/campaign-metrics',
      },
    ],
  },
  {
    key: 'leads',
    name: 'Leads',
    href: '/leads',
    icon: LeadIcon,
  },
  {
    key: 'Training',
    name: 'Training',
    icon: TrainingIcon,
    children: [
      {
        key: 'Training-hub',
        name: 'Training hub',
        href: TrainingHubUrl,
      },
      {
        key: 'Mortgage-Training',
        name: 'Mortgage broker',
        href: MortgageTrainingUrl,
      },
    ],
  },
];

const socialMediaCustomer = !!hasSocialMediaTrainingAccess();

const updatedNavigationRoutes = navigationRoutes.map((route) => {
  if (route.key === 'Training' && socialMediaCustomer) {
    return {
      ...route,
      children: [
        ...(route.children || []),
        {
          key: 'Social-media',
          name: 'Social media',
          href: SocialMediaTrainingUrl,
        },
      ],
    };
  }
  return route;
});

export default updatedNavigationRoutes;