import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { CheckIconGuides } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { getMyCheckout, getRouteGuidePreview } from 'modules/v2/routes/navigation';
import { GuideStatusButton, GuideCheckoutStatus } from 'modules/v2/utils/guideUtils';
import { cn } from 'modules/v2/common/utils';
import { htmlCreation } from 'modules/v2/pages/GuideCatalog/utils/htmlCreation';

export const GuidePreview = ({ guideDetails, frontCoverResponse, personalInformation }) => {
  const { name, guideColor, guideData, title } = guideDetails;
  const [selectedColor, setSelectedColor] = useState(guideColor[0]);
  const [selectedCoverImage, setSelectedCoverImage] = useState(guideData[0]);
  const history = useHistory();

  const handleCheckout = (status, orderId) => {
    history.push(getMyCheckout(status, orderId));
  };

  const handlePreviewClick = () => {
    history.push(getRouteGuidePreview());
  };

  const handleColorChange = (colorItem) => {
    setSelectedColor(colorItem);
    const selectedImg = guideData.filter(item => item.color === colorItem);
    setSelectedCoverImage(selectedImg[0]);
  };

  const checkButton = (status) => ({
    new: GuideStatusButton.NEW,
    ordered: GuideStatusButton.ORDERED,
  }[status] || GuideStatusButton.FINALIZED);

  const checkFinalizeStatus = selectedCoverImage.orderStatus === GuideCheckoutStatus.FINALIZE;

  const finalHTML = htmlCreation(frontCoverResponse, personalInformation, selectedColor);

  return (
    <div>
      <div className="relative group w-[252px] h-[332px] rounded-lg">
        <div className='bg-grey-100 relative w-[252px] h-[332px] rounded-lg border neutral-300 overflow-hidden cursor-pointer transition-transform duration-300 ease-in-out' >
          <div
            className="absolute top-0 left-0 w-full h-full origin-top-left scale-30"
            dangerouslySetInnerHTML={{ __html: finalHTML }}
          />
        </div>

        <div className="absolute inset-0 bg-black bg-opacity-40 rounded-lg flex flex-col items-center justify-center opacity-0 group-hover:opacity-100 transition-opacity duration-300">
          <Button type='outlined' className='mb-2 px-14' onClick={handlePreviewClick}>Preview</Button>
          <Button type='primary' className={cn('px-12', {
            'px-6': checkFinalizeStatus,
          })} onClick={() => handleCheckout(selectedCoverImage.orderStatus, selectedCoverImage.orderId)}> {checkButton(selectedCoverImage.orderStatus)} </Button>
        </div>
      </div>


      <div className="py-2 flex gap-2 flex-row">
        {guideColor.map((item) => (
          <div
            key={item}
            className={cn(`h-[26px] w-[26px] flex items-center justify-center rounded-full cursor-pointer bg-guides-${item}`)}
            onClick={() => handleColorChange(item)}
          >
            {selectedColor === item && <CheckIconGuides />}
          </div>
        ))}
      </div>
      <div className='text-sm font-medium'>
        <p className='truncate'>{title}</p>
        <span className='text-neutral-500'>{name}</span>
      </div>
    </div>
  );
};