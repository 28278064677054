interface GuideIconFilledProps {
    className?: string;
  }

  const GuideIconFilled = ({ className }: GuideIconFilledProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="21" height="21" viewBox="0 0 21 21" fill="none" className={className}>
            <path d="M5.21232 2.35349C4.85176 2.44771 4.61951 2.58091 4.43899 2.75777C4.1845 3.0071 4.01857 3.35717 3.92786 4.01822C3.83447 4.69872 3.83301 5.60062 3.83301 6.89379V14.0343C4.1569 13.8125 4.52195 13.6438 4.9155 13.5404C5.01048 13.5155 5.10886 13.496 5.21232 13.4806V2.35349Z" fill="#FAFAFA" />
            <path d="M6.59163 13.4253C6.60139 13.4253 6.61119 13.4253 6.62103 13.4253L17.1663 13.4253V6.89379C17.1663 5.60062 17.1649 4.69872 17.0715 4.01822C16.9808 3.35717 16.8148 3.0071 16.5604 2.75777C16.3059 2.50843 15.9486 2.34587 15.2738 2.25699C14.5792 2.1655 13.6587 2.16406 12.3388 2.16406H8.66059C7.82582 2.16406 7.15079 2.16464 6.59163 2.1882V13.4253Z" fill="#FAFAFA" />
            <path d="M6.72752 14.7767C5.82797 14.7767 5.51348 14.7825 5.27249 14.8457C4.61105 15.0194 4.08374 15.5011 3.85761 16.1272C3.87159 16.4459 3.8935 16.7262 3.92786 16.9766C4.01857 17.6376 4.1845 17.9877 4.43899 18.237C4.69349 18.4864 5.0508 18.6489 5.72553 18.7378C6.42011 18.8293 7.34067 18.8307 8.66059 18.8307H12.3388C13.6587 18.8307 14.5792 18.8293 15.2738 18.7378C15.9486 18.6489 16.3059 18.4864 16.5604 18.237C16.8148 17.9877 16.9808 17.6376 17.0715 16.9766C17.1477 16.4214 17.1627 15.7188 17.1656 14.7767H6.72752Z" fill="#FAFAFA" />
        </svg>
    );
};

export default GuideIconFilled;