import { useState } from 'react';
import { useQuery } from 'react-query';
import map from 'lodash/map';
import get from 'lodash/get';

import { FilePicker } from 'modules/v2/common/components';
import { getUploadedImages } from 'modules/api/digitalServices/uploads';
import { fetchPresetLogos } from 'modules/api';
import { addKeyToArrayOfObject } from 'modules/v2/utils/functions';
import env from 'environments';

import S from './styles';
import { mockLogos } from './constants';

const PageProfileModal = ({
  type,
  onImageClick,
  selectedProfileLogo,
  imageUrlForEdit,
  isEditImage,
  toggleCancelEdit,
  onSuccess,
  onImageConfirm,
}) => {
  const [photoGalleryList, setPhotoGalleryList] = useState([]);
  const [brokerageLogo, setBrokerageLogoList] = useState([]);

  useQuery(['uploads'], getUploadedImages, {
    keepPreviousData: true,
    onSuccess: (data) => {
      if (data?.length) {
        const structuredImagesList = addKeyToArrayOfObject(data, 'downloadUrl', 'imageUrl');
        setPhotoGalleryList(structuredImagesList);
      }
    },
  });

  useQuery(['bookLogos'], fetchPresetLogos, {
    keepPreviousData: true,
    onSuccess: (data) => {
      const files = get(data, 'data.data.files');
      if (files?.length) {
        const structuredImagesList = addKeyToArrayOfObject(
          files,
          'logourl.url',
          'imageUrl',
          (newVal) => env.GRAPHQL_URL + newVal,
        );
        setBrokerageLogoList(structuredImagesList);
      }
    },
  });

  const handleImageChange = (e) => {
    selectedProfileLogo(e);
  };

  const renderImages = (imageList) => {
    const hasImages = imageList?.length;
    if (!hasImages) {
      return (
        <S.GalleryContainer>
          <S.NoData>No Data</S.NoData>
        </S.GalleryContainer>
      );
    }
    return (
      <S.GalleryContainer>
        {map(imageList, (image) => {
          const key = image.imageUrl?.split('/').pop() || '';
          return (
            <S.ImageWrapper onClick={() => onImageClick(image)} key={key}>
              <S.UploadedImage src={image?.imageUrl} alt="" />
            </S.ImageWrapper>
          );
        })}
      </S.GalleryContainer>
    );
  };

  const showLogo = type === 'brokerageLogo' || type === 'companyLogo';

  return (
    <S.Tab type="card">
      <S.TabPane tab="Upload Image" key="1">
        <S.TabPaneWrapper>
          <FilePicker
            type={type}
            handleImageChange={handleImageChange}
            isEditImage={isEditImage}
            toggleCancelEdit={toggleCancelEdit}
            imageUrlForEdit={imageUrlForEdit}
            onSuccess={onSuccess}
            onImageConfirm={onImageConfirm}
          />
        </S.TabPaneWrapper>
      </S.TabPane>
      <S.TabPane tab="Photo Gallery" key="2">
        <S.TabPaneWrapper>{renderImages(photoGalleryList)}</S.TabPaneWrapper>
      </S.TabPane>
      {showLogo && (
        <S.TabPane tab="Logo Gallery" key="3">
          <S.TabPaneWrapper>{renderImages(brokerageLogo || mockLogos)}</S.TabPaneWrapper>
        </S.TabPane>
      )}
    </S.Tab>
  );
};

export default PageProfileModal;
