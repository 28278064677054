import axios from 'axios';
import qs from 'query-string';
import { merge } from 'lodash-es';

import env from 'environments';

const instance = axios.create({
  baseURL: env.STRAPI_URL,
  paramsSerializer: (params) => qs.stringify(params),
});

instance.interceptors.request.use((config) => {
  const Authorization = `Bearer ${env.STRAPI_BEARER_TOKEN}`;

  return merge({}, config, {
    headers: {
      Authorization,
    },
  });
});

export const getInstance = () => instance;