import { useEffect } from 'react';
import { compose } from 'redux';
import {
  getGlobalProfile,
  updateGlobalProfile,
  globalProfileSelector,
} from 'modules/dashboard/store';
import { Empty } from 'modules/v2/common/components';
import withStore from 'store/StoreContainer';
import { isInitial } from 'store/status';

const withGlobalProfile = (Component = Empty) => (props) => {
  const { globalProfile, getGlobalProfile: getGlobalProfileOnce } = props;

  useEffect(() => {
    if (isInitial(globalProfile.status)) {
      getGlobalProfileOnce();
    }
  }, [globalProfile, getGlobalProfileOnce]);

  return <Component {...props} />;
};

const mapDispatchToProps = {
  getGlobalProfile,
  updateGlobalProfile,
};

const mapStateToProps = (state) => ({
  globalProfile: globalProfileSelector(state),
});

export default compose(withStore(mapStateToProps, mapDispatchToProps), withGlobalProfile);