import { CloseCircleIcon, CheckIconNew } from 'modules/v2/common/components/SvgIcon';
import { cn } from 'modules/v2/common/utils';
import { ChangeEvent } from 'react';

interface SwitchButtonProps {
  checked: boolean;
  setChecked: (checked: boolean) => void;
  onChange?: (e: ChangeEvent<HTMLInputElement>) => void;
  className: string;
  showCloseIcon?: boolean;
  disabled: boolean;
  isUpgradePlan: boolean;
  color?: string;
}

const defaultProps: Partial<SwitchButtonProps> = {
  onChange: null,
  showCloseIcon: true,
};

const SwitchButton = ({
  checked,
  setChecked,
  onChange,
  className,
  showCloseIcon,
  disabled = false,
  isUpgradePlan = false,
  color,
}: SwitchButtonProps) => {
  const handleToggleButton = (event) => {
    setChecked && setChecked(!checked);
    if (onChange) {
      onChange(event);
    }
  };

  return (
    <div
      className={cn('w-10 h-6 rounded-full flex items-center p-0.5', className, {
        'cursor-not-allowed': disabled,
        'cursor-pointer': !disabled,
        'bg-neutral-800 justify-end': checked,
        'bg-toggleButtonBorder justify-start': !checked,
        'bg-primary-500': isUpgradePlan && checked,
        'bg-color': color && checked,
      })}
      style={{
        backgroundColor: color ? color : '#252525',
      }}
      onClick={handleToggleButton}
    >
      <div
        className="shadow w-[1.2rem] h-[1.2rem] flex justify-center items-center bg-white rounded-full"
        style={{
          backgroundColor: '#ffffff',
        }}
      >
        {checked ? (
          <CheckIconNew fill={isUpgradePlan ? '#4cc9e1' : color ? color : '#252525'} width={12} height={12} />
        ) : (
          showCloseIcon && <CloseCircleIcon fill="#D7D7D7" width={12} height={12} />
        )}
      </div>
    </div>
  );
};

SwitchButton.defaultProps = defaultProps;

export default SwitchButton;