export const PinMap = ({ width = 20, height = 20 }) => (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={width}
      height={height}
      viewBox="0 0 20 20"
      fill="none"
    >
      <path
        d="M3.33325 8.4527C3.33325 4.70486 6.31802 1.66663 9.99992 1.66663C13.6818 1.66663 16.6666 4.70486 16.6666 8.4527C16.6666 12.1712 14.5388 16.5103 11.219 18.062C10.4451 18.4237 9.5547 18.4237 8.78081 18.062C5.46102 16.5103 3.33325 12.1712 3.33325 8.4527Z"
        stroke="#38AB86"
        strokeWidth="1.5"
      />
      <circle cx="10" cy="8.33337" r="2.5" stroke="#38AB86" strokeWidth="1.5" />
    </svg>
  );