import { getValueFromToken } from './getValueFromToken';

export const hasOnlyRMAccess = () => {
  return getValueFromToken('hasOnlyRMAccess');
};

export const checkRMSubscriptionAccess = () => {
  const response = getValueFromToken('subscriptions');
  return response?.length > 0 && response.includes('RM');
};

export const cardMagazineImageUrl = 'https://afy-ui.s3.us-east-1.amazonaws.com/magazines-image.png';