import { useHistory } from 'react-router-dom';
import env from 'environments';
import { getRoutePlanUpgrade } from 'modules/v2/routes/navigation';
import { BoltIcon, TickMark } from 'modules/v2/common/components/SvgIcon';
import { Button } from 'modules/v2/common/AtomicDesign/atoms';
import { cardImageUrl } from 'modules/v2/utils/hideBookAccess';



function SubscribeBook() {
  const history = useHistory();
  const handleLearnMoreClick = () => {
    window.open(env.PRINTED_BOOKS_URL, '_blank');
  };

  const handleSubscribeClick = () => {
    history.push(getRoutePlanUpgrade());
  };

  return (
    <div className='flex items-start self-stretch p-6 bg-white rounded-lg shadow-md gap-5 w-full mb-6'>
      <div className='justify-center items-center gap-2.5 self-stretch w-[40%]'>
        <img className='rounded-lg h-full w-full' src={cardImageUrl} alt='book-asset'/>
      </div>
      <div className='w-[60%]'>
        <div className='text-lg font-bold'>
        Why Have Your Own Book?
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Your Book Instantly Transforms You Into A Trusted Authority.</span> Prospective clients will be impressed by the professional design, helpful content, and the fact that you’re a published author
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Use Your Book To Generate Leads.</span> Instead of offering the same thing as every other agent, you can offer something that makes you stand out.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>You Close More Appointments When You Have A Book.</span> When you give away your book at the beginning of a listing presentation. Sellers will be impressed, and every presentation will start off on the right foot.
          </div>
        </div>
        <div className='flex justify-center items-start gap-2 self-stretch my-2'>
          <div>
            <TickMark />
          </div>
          <div className='text-sm'>
            <span className='font-semibold'>Show People You Can Help Them By Actually Helping Them.</span> When you give prospective clients a book, you’re educating them on how everything works. This creates goodwill and subtly demonstrates your expertise.
          </div>
        </div>
        <div className='flex gap-2 mt-4 mb-2'>
          <Button type='primary' onClick={handleSubscribeClick}><span className='pr-1'><BoltIcon /></span> Buy Books</Button>
          <Button type='muted' onClick={handleLearnMoreClick}>Learn more</Button>
        </div>
      </div>
    </div>
  );
}

export default SubscribeBook;