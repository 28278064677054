import { createContext, useMemo, useState } from 'react';
import { getItem } from 'modules/v2/utils/legacy';

export const GuidesContext = createContext(null);

export function GuidesProvider({ children }) {
  const [guideQuantity, setGuideQuantity] = useState(8);
  const [isGuideQuantityValid, setIsGuideQuantityValid] = useState(true);
  const [selectedOrderContext, setSelectedOrderContext] = useState({});
  const [selectedAddress, setSelectedAddress] = useState({});
  const [activePage, setActivePage] = useState('ordered-guides');
  const [userAddressesContext, setUserAddressesContext] = useState([]);
  const userDataString = getItem('userData');
  const userData = userDataString ? JSON.parse(userDataString) : null;

  const clearContext = () => {
    setGuideQuantity(8);
    setIsGuideQuantityValid(true);
    setSelectedOrderContext({});
    setSelectedAddress({});
    setActivePage('ordered-guides');
    setUserAddressesContext([]);
  };

  const contextData = {
    clearContext,
    userData,
    guideQuantity,
    setGuideQuantity,
    isGuideQuantityValid,
    setIsGuideQuantityValid,
    selectedOrderContext,
    setSelectedOrderContext,
    activePage,
    setActivePage,
    selectedAddress,
    setSelectedAddress,
    userAddressesContext,
    setUserAddressesContext,
  };

  const memoizedContextData = useMemo(() => contextData, [
    contextData,
    guideQuantity,
    isGuideQuantityValid,
    selectedOrderContext,
    selectedAddress,
    activePage,
    userAddressesContext,
  ]);

  return <GuidesContext.Provider value={memoizedContextData}>{children}</GuidesContext.Provider>;
}