import { useState, useEffect } from 'react';
import AvatarEditor from 'react-avatar-editor';
import Dropzone from 'react-dropzone';
import env from 'environments';
import { createUploads } from 'modules/api';
import Loader from 'modules/v2/common/components/Loader';
import Button from 'modules/v2/common/AtomicDesign/atoms/Button';

import S from './styles';

const ImageCropper = ({ image, type, onSuccess, handleCancel, onChange }) => {
  let editor = '';
  const [picture, setPicture] = useState({
    cropperOpen: true,
    img: image,
    zoom: 1,
    croppedImg: '',
    previewImg: '',
  });

  const [allowedDimension, setallowedDimension] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [description, setDescription] = useState('');
  const [value, setValue] = useState(0);

  useEffect(() => {
    switch (type) {
      case 'rm_profile':
        return setallowedDimension({ width: 520, height: 520 });
      case 'book_profile':
        return setallowedDimension({ width: 520, height: 520 });
      case 'brokerageLogo':
        return setallowedDimension({ width: 520, height: 192 });
      case 'companyLogo':
        return setallowedDimension({ width: 274, height: 154 });
      case 'backInsideCoverImageOption3and4':
        return setallowedDimension({ width: 1440, height: 960 });
      case 'backInsideCoverImageOption6':
        return setallowedDimension({ width: 960, height: 672 });
      case 'backCoverImageOption4and5':
        return setallowedDimension({ width: 960, height: 750 });
      case 'backCoverImageOption3':
        return setallowedDimension({ width: 600, height: 960 });
      default:
        return setallowedDimension({ width: 500, height: 500 });
    }
  }, [type]);

  const setEditorRef = (ed) => {
    editor = ed;
  };

  const handleSave = () => {
    setIsLoading(true);
    setDescription('Please wait, uploading image');
    if (setEditorRef) {
      const canvasScaled = editor.getImageScaledToCanvas();
      const ImageURL = canvasScaled.toDataURL();
      onChange(ImageURL);

      fetch(ImageURL)
        .then((res) => {
          return res.blob();
        })
        .then(async (images) => {
          if (type !== 'book_profile') {
            try {
              const path = '';
              const req = {
                payload: {
                  bucket: env.DS_BUCKET,
                  isPrivate: false,
                  ext: 'png',
                  contentType: 'image/png',
                  path,
                },
                callbackTargetKey: 'uploadUrl',
                callbackPayload: images,
              };
              const response = await createUploads(req);
              onSuccess?.(response);
            } catch (err) {
              throw new Error(err);
            }
          }
        });

      setIsLoading(false);
    }
  };

  const handleDrop = async (files) => {
    const url = URL.createObjectURL(files[0]);
    setPicture({
      ...picture,
      img: url,
      cropperOpen: true,
    });
  };

  const handleScale = (e) => {
    setValue(e.target.value);
    const scale = parseFloat(e.target.value);
    setPicture({
      ...picture,
      zoom: scale > 1 ? scale : 1,
    });
  };

  return (
    <>
      {isLoading ? (
        <Loader height="75vh" hasIcon={false} description={description} />
      ) : (
        <div className="flex flex-col items-center justify-center">
          <S.ResetModalHeightStyle />
          <S.DragDropWrapper noMargin className="w-fit border-none">
            {allowedDimension && (
              <Dropzone
                noClick
                onDrop={handleDrop}
                noKeyboard
                style={{ width: '250px', height: '250px', backgroundColor: '#fff' }}
              >
                {({ getRootProps }) => (
                  <div {...getRootProps()}>
                    <AvatarEditor
                      ref={setEditorRef}
                      image={picture.img}
                      width={allowedDimension.width}
                      height={allowedDimension.height}
                      border={30}
                      color={[0, 0, 0, 0.2]}
                      rotate={0}
                      scale={picture.zoom}
                    />
                  </div>
                )}
              </Dropzone>
            )}
          </S.DragDropWrapper>
          <div className="flex flex-col gap-1 w-full mt-3">
            <S.InputRange
              name="scale"
              type="range"
              onChange={handleScale}
              min="1"
              max="4"
              step="0.01"
              defaultValue="1"
              value={value}
            />
            <div className="flex gap-x-2 justify-end">
              <Button type="outlined" onClick={handleCancel}>
                Cancel
              </Button>
              <Button type="dark" onClick={handleSave}>
                Save changes
              </Button>
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default ImageCropper;
