import { useState } from 'react';
import { BrokerLogoModal } from 'modules/v2/common/components';
import { Button, LabelInput, TextInput, SwitchButton } from 'modules/v2/common/AtomicDesign/atoms';

interface LogoUploaderProps {
    imageUrl?: string | null;
    setImageUrl?: (value: string) => void;
    dimensions?: { width: number, height: number };
    modalTitle?: string;
    switchButtonText?: string;
}

const LogoUploader = ({
    imageUrl = '',
    setImageUrl,
    dimensions = { width: 274, height: 154 },
    modalTitle = 'Upload Broker Logo',
}: LogoUploaderProps) => {
    const [toggleBrokerInfo, setToggleBrokerInfo] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    const type = 'companyLogo';

    const onSuccess = (value) => {
        setImageUrl(value);
        setIsOpen(false);
    };
    const onCancel = () => setIsOpen(false);
    const handleOpenModal = () => setIsOpen(true);
    const onRemoveImage = () => setImageUrl(null);

    const handleIsValidCompany = (value: string) => value.length > 0;

    return (
        <>
            <div className='flex items-center gap-3.5'>
                <SwitchButton checked={toggleBrokerInfo} setChecked={setToggleBrokerInfo} color='#4CC9E1' />
                <span className="text-sm font-semibold">
                    Add Your Brokerage
                </span>
            </div>
            {toggleBrokerInfo && <div className='w-full  mt-3.5'>
                <BrokerLogoModal
                    title={modalTitle}
                    type={type}
                    isOpen={isOpen}
                    onCancel={onCancel}
                    onSuccess={onSuccess}
                    imageUrlForEdit={imageUrl}
                    toggleCancelEdit={() => setIsOpen(false)}
                />
                <div
                    className="w-full pr-2 flex justify-start mb-3.5 max-[600px]:overflow-x-auto"
                    onClick={handleOpenModal}
                >
                    <div>
                        <p className='text-sm font-semibold'>Broker Company Logo<span className='text-error-400'>*</span></p>
                        <p className='text-sm font-medium text-neutral-500'>Recommended size: {dimensions.width} x {dimensions.height}</p>
                    </div>
                </div>

                {imageUrl ? (
                    <div className="flex gap-3 items-center">
                        <div className={`w-[${dimensions.width}px] h-[${dimensions.height}px] border border-neutral-200 rounded-md bg-white flex items-center justify-center`}>
                            <img src={imageUrl} alt={type} className={`w-[${dimensions.width}px] h-[${dimensions.height}px] object-container rounded-md`} />
                        </div>
                        <Button
                            color="light"
                            onClick={handleOpenModal}
                            className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
                        >
                            Change
                        </Button>
                        <Button
                            color="light"
                            onClick={onRemoveImage}
                            className="border-neutral-200 hover:border-neutral-500 hover:bg-neutral-50 h-11"
                        >
                            Remove
                        </Button>
                    </div>
                ) : (
                    <div className='w-1/2'>
                    <Button
                        type="outlined"
                        full
                        onClick={handleOpenModal}
                    >
                        Upload logo
                    </Button>
                    </div>
                )}
                <div>
                    <div className="flex-1 flex flex-col mt-6">
                        <div className="w-full max">
                            <LabelInput required>Company name</LabelInput>
                            <TextInput
                                type="text"
                                name="company"
                                sizing='sm'
                                className="w-full"
                                errorMessage="Please enter the company name"
                            onChange={(e) => {
                                console.log(e.target.value);
                            }}
                            isValid={(e) => handleIsValidCompany(e.target.value)}
                            />
                        </div>
                    </div>
                </div>
            </div>}
        </>
    );
};

export default LogoUploader;