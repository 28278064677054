import axios from 'axios';
import qs from 'query-string';
import { merge } from 'lodash-es';

import env from 'environments';
import { getItem } from 'modules/dashboard/utils/legacy';

const instance = axios.create({
  baseURL: env.API_AUTHORIFY_URL,
  paramsSerializer: (params) => qs.stringify(params),
});

instance.interceptors.request.use((config) => {
  const token = getItem('token');
  const Authorization = `Bearer ${token}`;

  return merge({}, config, {
    headers: {
      Authorization,
    },
  });
});

instance.interceptors.response.use(undefined, (error) => {
  // Handling unauthorized requests
  if (error.response && error.response.status === 401) {
    window.location.href = '/auth/login?expired=1';
  }

  return Promise.reject(new Error(error));
});

export const getInstance = () => instance;