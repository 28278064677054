import { getInstance } from '../instance';


const instance = getInstance();


const mockResponse = {
    success: true,
    message: "Guide published successfully",
    guideId: 1234,
};


// Publish a guide
// Waiting for API integration
const publishGuide = async (data: any) => {
    //   const response = await instance.post('/guides/publish', data);
    //   return response.data;


    console.log("Mock publish guide data:", data);
    return new Promise((resolve) => {
        setTimeout(() => {
            resolve(mockResponse);
        }, 3000);
    });
};


export { publishGuide };