interface InfographicIconFilledProps {
    className?: string;
  }

  const InfographicIconFilled = ({ className }: InfographicIconFilledProps) => {
    return (
        <svg xmlns="http://www.w3.org/2000/svg" width="20" height="21" viewBox="0 0 20 21" fill="none" className={className}>
            <path fillRule="evenodd" clipRule="evenodd" d="M3.47631 3.14037C2.5 4.11668 2.5 5.68803 2.5 8.83073V12.1641C2.5 15.3068 2.5 16.8781 3.47631 17.8544C4.45262 18.8307 6.02397 18.8307 9.16667 18.8307H10.8333C13.976 18.8307 15.5474 18.8307 16.5237 17.8544C17.5 16.8781 17.5 15.3068 17.5 12.1641V8.83073C17.5 5.68803 17.5 4.11668 16.5237 3.14037C15.5474 2.16406 13.976 2.16406 10.8333 2.16406H9.16667C6.02397 2.16406 4.45262 2.16406 3.47631 3.14037ZM6.66667 8.20573C6.32149 8.20573 6.04167 8.48555 6.04167 8.83073C6.04167 9.17591 6.32149 9.45573 6.66667 9.45573H13.3333C13.6785 9.45573 13.9583 9.17591 13.9583 8.83073C13.9583 8.48555 13.6785 8.20573 13.3333 8.20573H6.66667ZM6.66667 11.5391C6.32149 11.5391 6.04167 11.8189 6.04167 12.1641C6.04167 12.5092 6.32149 12.7891 6.66667 12.7891H10.8333C11.1785 12.7891 11.4583 12.5092 11.4583 12.1641C11.4583 11.8189 11.1785 11.5391 10.8333 11.5391H6.66667Z" fill="#FAFAFA" />
        </svg>
    );
};

export default InfographicIconFilled;